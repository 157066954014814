import GridHelper from '@/utils/gridUtils'
import UserGridViewService from '@/service/userGridView.service'

export default {
  data() {
    return {
      gridId: 'mixin',
      dataState: GridHelper.createDefaultDataState(),
      originalDataState: null,
      columns: [],
      originalColumns: null,
      currentViewName: null,
      currentViewId: null,
    }
  },
  computed: {
    gridStateKey() {
      return `gridstate_${this.gridId}`
    },
  },
  created() {
    // save original column status for later use (e.g. resetting view)
    this.originalColumns = this.activeColumns.concat().map(c => ({
      field: c.field,
      orderIndex: c.orderIndex,
      locked: c.locked,
      width: c.width,
      hidden: c.hidden,
    }))

    this.originalDataState = JSON.parse(JSON.stringify(this.dataState)) // clone dataState to prevent ref to original variable
    this.loadLatestGridState()
  },
  methods: {
    onDataStateChange(e) {
      this.dataState = e.data
      this.saveCurrentGridState()
    },
    onColumnReorder(options) {
      this.columns = options.columns
      this.saveCurrentGridState()
    },
    onFilterChange(e) {
      this.$set(this.dataState, 'filter', e.filter)

      // filter is set, reset skip. otherwise only data is filtered after skip
      if (e.filter) {
        this.dataState.skip = 0
      }

      this.saveCurrentGridState()
      GridHelper.highlightColumnsWithSortOrFilter(this.columns, this.dataState)
    },
    onSortChange(e) {
      this.$set(this.dataState, 'sort', e.sort)

      this.saveCurrentGridState()
      GridHelper.highlightColumnsWithSortOrFilter(this.columns, this.dataState)
    },

    saveCurrentGridState() {
      const view = GridHelper.createView(this.columns, this.dataState, this.currentViewName, this.currentViewId)
      sessionStorage.setItem(this.gridStateKey, JSON.stringify(view))
    },
    loadLatestGridState() {
      const gridStateJson = sessionStorage.getItem(this.gridStateKey)
      if (gridStateJson !== null) {
        const view = JSON.parse(gridStateJson)
        this.updateState(view.columns, view.dataState, view.displayName, view.id)
      } else {
        // No grid state in session storage found --> load default view
        UserGridViewService.getDefaultAsync(this.gridId)
          .then(result => {
            if (result && result.data) {
              this.onApplyView({ view: result.data.viewData, displayName: result.data.displayName, id: result.data.id })
            }
          })
      }

      GridHelper.highlightColumnsWithSortOrFilter(this.columns, this.dataState)
    },
    resetToDefaultView() {
      this.updateState(this.originalColumns, this.originalDataState, null, null)
      sessionStorage.removeItem(this.gridStateKey)
      GridHelper.highlightColumnsWithSortOrFilter(this.columns, this.dataState)
    },
  },
}
