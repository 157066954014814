<template>
  <div class="gridviews-dropdown-wrapper">
    <b-dropdown
      text="Views"
      :variant="gridViewIsOriginal ? 'secondary' : 'outline-info'"
      size="sm"
      :popper-opts="{'positionFixed': true}"
      :lazy="true"
      @show="loadUserViews"
    >
      <b-dropdown-header>Personal views</b-dropdown-header>
      <b-dropdown-item
        v-for="userView in userViews"
        :key="userView.id"
        @click="onClickUserView(userView)"
      >
        {{ userView.displayName }}
        <span
          v-if="userView.isDefault"
          class="text-muted"
        >
          <br>Default
        </span>
      </b-dropdown-item>
      <b-dropdown-text v-if="userViews.length === 0 && !isUserViewsLoading">
        Views do not exist yet
      </b-dropdown-text>
      <b-dropdown-text v-if="isUserViewsLoading">
        <div class="d-flex justify-content-center">
          <b-spinner label="Loading..." />
        </div>
      </b-dropdown-text>
      <b-dropdown-divider />
      <b-dropdown-header>Actions</b-dropdown-header>
      <b-dropdown-item @click="onSaveCurrentView">
        Save current view
      </b-dropdown-item>
      <b-dropdown-item v-b-modal.manage-modal>
        Manage my views
      </b-dropdown-item>
      <b-dropdown-item
        v-b-tooltip.hover
        title="Reset the current view to default settings."
        @click="resetToDefaultView"
      >
        Reset view
      </b-dropdown-item>
    </b-dropdown>
    <span
      v-show="currentViewName"
      class="cur-viewname"
    >{{ currentViewName }}</span>
    <b-button
      v-if="defaultViewExists"
      v-b-tooltip.hover
      style="margin-left: 8px"
      size="sm"
      variant="secondary"
      title="Apply default view"
      @click="applyDefaultView"
    >
      Default
    </b-button>
    <b-button
      v-if="currentViewId"
      style="margin-left: 8px"
      size="sm"
      variant="secondary"
      @click="resetFilters"
    >
      Reset filter
    </b-button>

    <b-modal
      id="manage-modal"
      title="Manage views"
      ok-only
      ok-title="Close"
      @show="loadUserViews"
    >
      <div
        v-if="isUserViewsLoading"
        class="d-flex justify-content-center"
      >
        <b-spinner label="Loading..." />
      </div>

      <p
        v-if="userViews.length === 0 && !isUserViewsLoading"
        class="d-flex justify-content-center"
      >
        Views do not exist yet
      </p>

      <table
        v-if="!isUserViewsLoading"
        class="table"
      >
        <tbody>
          <tr
            v-for="userView in userViews"
            :key="userView.id"
          >
            <td>
              {{ userView.displayName }}
              <small
                v-if="userView.isDefault"
                class="text-muted"
              ><br>Default view</small>
            </td>
            <td>
              <b-button-group>
                <b-button
                  v-if="!userView.isDefault"
                  title="Set as default"
                  size="sm"
                  variant="outline-secondary"
                  @click="setAsDefault(userView)"
                >
                  <feather-icon
                    icon="CrosshairIcon"
                  />
                </b-button>
                <b-button
                  v-if="userView.isDefault"
                  title="Unset default"
                  size="sm"
                  variant="secondary"
                  @click="unsetAsDefault(userView)"
                >
                  <feather-icon
                    icon="CrosshairIcon"
                  />
                </b-button>
                <b-button
                  title="Delete"
                  size="sm"
                  variant="danger"
                  @click="deleteUserView(userView)"
                >
                  Delete
                </b-button>
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BDropdownHeader,
  BDropdownText,
  BSpinner,
  BModal,
  VBTooltip,
} from 'bootstrap-vue'

import GridHelper from '@/utils/gridUtils'
import UserGridViewService from '@/service/userGridView.service'

export default {
  components: {
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BDropdownHeader,
    BDropdownText,
    BSpinner,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    gridId: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    dataState: {
      type: Object,
      required: true,
    },
    originalColumns: {
      type: Array,
      required: true,
    },
    originalDataState: {
      type: Object,
      required: true,
    },
    currentViewName: {
      type: String,
      required: false,
      default: null,
    },
    currentViewId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      userViews: [],
      userViewsLoaded: false,
      isUserViewsLoading: false,
      localStorageKey: 'gridviews',
    }
  },
  computed: {
    gridStateKey() {
      return `gridstate_${this.gridId}`
    },
    gridViewIsOriginal() {
      for (let i = 0; i < this.originalColumns.length; i += 1) {
        const originalColumn = this.originalColumns[i]
        const originalColumnLocked = typeof originalColumn.locked === 'undefined' ? false : originalColumn.locked
        const originalColumnHidden = typeof originalColumn.hidden === 'undefined' ? false : originalColumn.hidden
        const originalColumnOrderIndex = typeof originalColumn.orderIndex === 'undefined' ? false : originalColumn.orderIndex

        for (let j = 0; j < this.columns.length; j += 1) {
          const column = this.columns[j]
          const columnLocked = typeof column.locked === 'undefined' ? false : column.locked
          const columnHidden = typeof column.hidden === 'undefined' ? false : column.hidden
          const columnOrderIndex = typeof column.orderIndex === 'undefined' ? false : column.orderIndex

          if (originalColumn.field === column.field) {
            if (originalColumnLocked !== columnLocked
                || originalColumnHidden !== columnHidden
                || originalColumnOrderIndex !== columnOrderIndex) {
              return false
            }
          }
        }
      }

      return true
    },
    defaultViewExists() {
      return this.userViews.some(x => x.isDefault === true)
    },
  },
  created() {
    this.loadUserViews()
  },
  methods: {
    onClickUserView(userView) {
      const self = this

      UserGridViewService.getAsync(userView.id)
        .then(result => {
          self.$emit('applyView', { view: result.data.viewData, displayName: result.data.displayName, id: result.data.id })
        })
    },
    loadUserViews() {
      if (this.userViewsLoaded === false) {
        this.isUserViewsLoading = true

        UserGridViewService.getListByGridAsync(this.gridId)
          .then(result => {
            this.userViews = result.data
            this.userViewsLoaded = true
          })
          .finally(() => {
            this.isUserViewsLoading = false
          })
      }
    },
    onSaveCurrentView() {
      const self = this

      this.$swal({
        title: 'Name your view',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        preConfirm: name => {
          const view = {
            displayName: name,
            gridId: self.gridId,
            viewData: GridHelper.createView(self.columns, self.dataState),
          }

          const existingViewWithSameName = self.userViews.find(x => x.displayName === name)

          if (name === null || name.length === 0) {
            self.$swal.showValidationMessage('Please insert a name for your view.')
          } else if (name.length > 50) {
            self.$swal.showValidationMessage('Please use max. 50 characters for the name of your view.')
          } else if (existingViewWithSameName) {
            self.$swal.showValidationMessage('A view with the same name already exists.')

            self.$swal({
              title: 'Do you want to overwrite the existing view?',
              icon: 'info',
              showCancelButton: false,
              showDenyButton: true,
              confirmButtonText: 'Yes',
              denyButtonText: 'No',
            }).then(result => {
              if (result.isConfirmed) {
                const { isDefault } = existingViewWithSameName
                view.isDefault = isDefault // Set isDefault if existing view was default too

                // delete existing view
                UserGridViewService.deleteAsync(existingViewWithSameName.id)
                  .then(() => {
                    self.userViews = self.userViews.filter(x => x.displayName !== name) // remove from local data

                    // create new view
                    UserGridViewService.createAsync(view)
                      .then(createResult => {
                        // add new view
                        self.$emit('applyView', { view: createResult.data.viewData, displayName: createResult.data.displayName, id: createResult.data.id })
                        self.userViews.push(createResult.data)
                        self.$toast('Your view has been saved!')
                      })
                  })
              }
            })
          } else {
            UserGridViewService.createAsync(view)
              .then(createResult => {
              // add new view
                self.$emit('applyView', { view: createResult.data.viewData, displayName: createResult.data.displayName, id: createResult.data.id })
                self.userViews.push(createResult.data)
                self.$toast('Your view has been saved!')
              })
          }
        },
      })
    },
    deleteUserView(userView) {
      const self = this
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          UserGridViewService.deleteAsync(userView.id)
            .then(() => {
              self.userViews = self.userViews.filter(item => item.id !== userView.id)
              this.$toast('Your view has been deleted.')
            })
        }
      })
    },
    setAsDefault(userView) {
      const self = this
      UserGridViewService.setDefaultAsync(userView.id)
        .then(() => {
          this.$toast('View has been set to default.')

          for (let i = 0; i < self.userViews.length; i += 1) {
            if (self.userViews[i].id === userView.id) {
              self.userViews[i].isDefault = true
            } else {
              self.userViews[i].isDefault = false
            }
          }
        })
    },
    unsetAsDefault(userView) {
      const self = this
      UserGridViewService.unsetDefaultAsync(userView.id)
        .then(() => {
          this.$toast('View updated.')

          for (let i = 0; i < self.userViews.length; i += 1) {
            self.userViews[i].isDefault = false
          }
        })
    },
    resetToDefaultView() {
      this.$emit('resetToDefaultViewClick')
    },
    applyDefaultView() {
      const self = this

      const defaultUserViews = this.userViews.filter(x => x.isDefault === true)

      if (defaultUserViews.length === 1) {
        UserGridViewService.getAsync(defaultUserViews[0].id)
          .then(result => {
            self.$emit('applyView', { view: result.data.viewData, displayName: result.data.displayName, id: result.data.id })
          })
      }
    },
    resetFilters() {
      const self = this

      if (this.currentViewId) {
        UserGridViewService.getAsync(this.currentViewId)
          .then(result => {
            self.$emit('applyView', { view: result.data.viewData, displayName: result.data.displayName, id: result.data.id })
          })
      }
    },
    updateView(cols, dataState) {
      this.dataState = dataState

      const filteredCols = cols.filter(c => c.field !== null && typeof c.field !== 'undefined')
      for (let i = 0; i < filteredCols.length; i += 1) {
        const col = filteredCols[i]
        for (let j = 0; j < this.activeColumns.length; j += 1) {
          const activeColumn = this.activeColumns[j]
          if (activeColumn.field === col.field) {
            activeColumn.orderIndex = col.orderIndex
            activeColumn.locked = col.locked
            activeColumn.width = col.width
            activeColumn.hidden = col.hidden
          }
        }
      }
    },
  },
}

</script>

<style scoped>
  .gridviews-dropdown-wrapper >>> .dropdown-menu {
    max-height: 80vh;
    overflow-y: scroll;
    z-index: 100;
  }

  .cur-viewname {
    margin: 0 5px 0 5px;
    align-self: center;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
</style>
