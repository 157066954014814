var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('Grid',{attrs:{"data-items":_vm.result,"columns":_vm.columns,"column-menu":true,"take":_vm.dataState.take,"skip":_vm.dataState.skip,"sort":_vm.dataState.sort,"filter":_vm.dataState.filter,"group":_vm.dataState.group,"expand-field":'expanded',"sortable":true,"reorderable":true,"resizable":true,"groupable":false,"pageable":true,"filterable":true,"page-size":50},scopedSlots:_vm._u([{key:"cellActionTemplate",fn:function(ref){
var props = ref.props;
return [_c('DetailLinkActionCellTemplate',{attrs:{"field":props.field,"row-type":props.rowType,"class-name":props.className,"to":"incident-detail","to-params":{ id: props.dataItem.id}}})]}},{key:"cellDurationTemplate",fn:function(ref){
var props = ref.props;
return [_c('DurationByMsCellTemplate',{attrs:{"field":props.field,"row-type":props.rowType,"class-name":props.className,"duration":props.dataItem.duration}})]}},{key:"assetSystemNumberCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('LinkCellTemplate',{attrs:{"field":props.field,"row-type":props.rowType,"class-name":props.className,"text":props.dataItem.asset.systemNumber,"to":{ name: 'asset-to-asup-redirect', params: { id: props.dataItem.asset.id }, query: { ar: true } }}})]}},{key:"assetHostnameCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('LinkCellTemplate',{attrs:{"field":props.field,"row-type":props.rowType,"class-name":props.className,"text":props.dataItem.asset.hostname,"to":{ name: 'asset-to-asup-redirect', params: { id: props.dataItem.asset.id }, query: { ar: true } }}})]}}])},[_c('GridToolbar',[[_c('div',{staticStyle:{"margin-left":"auto"}},[_c('b-button-group',{staticClass:"mr-2"},[_c('b-button',{attrs:{"size":"sm","variant":_vm.enableAssetFilter ? 'primary' : 'secondary'},on:{"click":function($event){_vm.enableAssetFilter = !_vm.enableAssetFilter}}},[_vm._v(" Asset Tickets ")]),(_vm.asset && _vm.asset.tenantId)?_c('b-button',{attrs:{"size":"sm","variant":!_vm.enableAssetFilter ? 'primary' : 'secondary'},on:{"click":function($event){_vm.enableAssetFilter = !_vm.enableAssetFilter}}},[_vm._v(" Organization Tickets ")]):_vm._e()],1),_c('b-button-group',[_c('b-button',{attrs:{"size":"sm","variant":_vm.selectedFilter === 'open' ? 'primary' : 'secondary'},on:{"click":function($event){_vm.selectedFilter = 'open'}}},[_vm._v(" Open ")]),_c('b-button',{attrs:{"size":"sm","variant":_vm.selectedFilter === 'all' ? 'primary' : 'secondary'},on:{"click":function($event){_vm.selectedFilter = 'all'}}},[_vm._v(" All ")])],1)],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }