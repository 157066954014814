<template>
  <div
    v-if="isCurrentUserHost"
  >
    <b-card>
      <template #header>
        <h4 class="card-title">
          Tickets
        </h4>
      </template>
      <IncidentList :asset="asset" />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCard } from 'bootstrap-vue'
import IncidentList from '@/components/incidents/IncidentList.vue'

export default {
  components: {
    IncidentList,
    BCard,
  },
  props: {
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
  },
}
</script>
