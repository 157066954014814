<template>
  <td
    v-if="renderCell"
    class="pt-0 pb-0"
  >
    <ul class="adminUserList">
      <li
        v-for="adminUser in dataItem.adminUsers"
        :key="adminUser.id"
      >
        <b-link :to="{ name: 'host:manage:tenant-user-detail', params: { userid: adminUser.id, id: dataItem.id } }">
          {{ adminUser.email }}
        </b-link>
      </li>
    </ul>
  </td>
</template>

<script>

import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    dataItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
  },
}
</script>

<style scoped>
    ul.adminUserList {
        margin: 10px 0 10px 0;
        padding-left: 10px;
    }
</style>
