<template>
  <td
    v-if="renderCell"
    class="pt-0 pb-0"
  >
    <div
      :id="`${asupId}_popover_${isHostStatus ? 'host' : 'tenant'}`"
    >
      <monitor-status-icon :status="asupStatus" />

      <b-popover
        custom-class="wide-popover"
        :target="`${asupId}_popover_${isHostStatus ? 'host' : 'tenant'}`"
        :disabled="asupStatus === 0"
        triggers="hover"
        :variant="popoverVariant"
        @show="onShow"
      >
        <template #title>
          Status
        </template>

        <b-overlay :show="isLoading">
          <p v-if="isLoading">
            Loading...
          </p>

          <div
            v-if="!isLoading"
          >
            <div
              v-for="alert in activeAlerts"
              :key="alert.monitorId"
            >
              <monitor-status-icon
                :status="alert.status"
                :text="alert.shortMessage || alert.exceptionMessage"
              />
            </div>
          </div>
        </b-overlay>
      </b-popover>
    </div>
  </td>
</template>

<script>

import { BPopover, BOverlay } from 'bootstrap-vue'
import MonitorStatusIcon from '@/components/monitor/MonitorStatusIcon.vue'

import AsupStatusService from '@/service/asupStatus.service'

export default {
  components: {
    BPopover,
    BOverlay,
    MonitorStatusIcon,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    asupId: {
      type: String,
      required: true,
    },
    asupStatus: {
      type: Number,
      default: () => null,
    },
    isHostStatus: {
      type: Boolean,
      required: true,
    },
    tenantId: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      isLoading: false,
      dataLoaded: false,
      alerts: [],
    }
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
    activeAlerts() {
      return this.alerts
        .concat()
        .filter(x => x.status !== 0)
        .sort((a, b) => (a.status > b.status ? -1 : 1))
    },
    popoverVariant() {
      switch (this.asupStatus) {
        case 0: return 'success'
        case 10: return 'info'
        case 20: return 'warning'
        case 30:
          return 'danger'
        default: return 'danger'
      }
    },
  },
  watch: {
    asupId() {
      /// cell stays the same after reordering grid. So we need to empty alerts cuz it could hold information about the data inside the same cell before reordering
      this.alerts = []
      this.dataLoaded = false // force reloading data for next time
    },
  },
  methods: {
    onShow() {
      if (this.dataLoaded === false) {
        this.isLoading = true

        AsupStatusService.getActiveListAsync({ asupSystemId: this.asupId }, { impersonateAsTenant: this.tenantId })
          .then(result => {
            this.alerts = result.items
          })
          .finally(() => {
            this.isLoading = false
            this.dataLoaded = true
          })
      }
    },
  },
}
</script>
