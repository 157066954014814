<template>
  <div>
    <b-overlay :show="isLoading">
      <Grid
        :data-items="result"
        :columns="columns"
        :column-menu="true"
        :take="dataState.take"
        :skip="dataState.skip"
        :sort="dataState.sort"
        :filter="dataState.filter"
        :group="dataState.group"
        :expand-field="'expanded'"
        :sortable="true"
        :reorderable="true"
        :resizable="true"
        :groupable="false"
        :pageable="true"
        :filterable="true"
        :page-size="50"
      >
        <!-- Custom toolbar -->
        <GridToolbar>
          <template>
            <div
              style="margin-left: auto"
            >
              <b-button-group class="mr-2">
                <b-button
                  size="sm"
                  :variant="enableAssetFilter ? 'primary' : 'secondary'"
                  @click="enableAssetFilter = !enableAssetFilter"
                >
                  Asset Tickets
                </b-button>
                <b-button
                  v-if="asset && asset.tenantId"
                  size="sm"
                  :variant="!enableAssetFilter ? 'primary' : 'secondary'"
                  @click="enableAssetFilter = !enableAssetFilter"
                >
                  Organization Tickets
                </b-button>
              </b-button-group>

              <b-button-group>
                <b-button
                  size="sm"
                  :variant="selectedFilter === 'open' ? 'primary' : 'secondary'"
                  @click="selectedFilter = 'open'"
                >
                  Open
                </b-button>
                <b-button
                  size="sm"
                  :variant="selectedFilter === 'all' ? 'primary' : 'secondary'"
                  @click="selectedFilter = 'all'"
                >
                  All
                </b-button>
              </b-button-group>
            </div>
          </template>
        </GridToolbar>

        <!-- Custom action cell -->
        <template v-slot:cellActionTemplate="{ props }">
          <DetailLinkActionCellTemplate
            :field="props.field"
            :row-type="props.rowType"
            :class-name="props.className"
            to="incident-detail"
            :to-params="{ id: props.dataItem.id}"
          />
        </template>

        <template v-slot:cellDurationTemplate="{ props }">
          <DurationByMsCellTemplate
            :field="props.field"
            :row-type="props.rowType"
            :class-name="props.className"
            :duration="props.dataItem.duration"
          />
        </template>

        <template v-slot:assetSystemNumberCellTemplate="{ props }">
          <LinkCellTemplate
            :field="props.field"
            :row-type="props.rowType"
            :class-name="props.className"
            :text="props.dataItem.asset.systemNumber"
            :to="{ name: 'asset-to-asup-redirect', params: { id: props.dataItem.asset.id }, query: { ar: true } }"
          />
        </template>

        <template v-slot:assetHostnameCellTemplate="{ props }">
          <LinkCellTemplate
            :field="props.field"
            :row-type="props.rowType"
            :class-name="props.className"
            :text="props.dataItem.asset.hostname"
            :to="{ name: 'asset-to-asup-redirect', params: { id: props.dataItem.asset.id }, query: { ar: true } }"
          />
        </template>
      </Grid>
    </b-overlay>
  </div>
</template>
<script>
import {
  BButton, BButtonGroup, BOverlay,
} from 'bootstrap-vue'
import { process } from '@progress/kendo-data-query'
import { Grid, GridToolbar } from '@progress/kendo-vue-grid'
import { mapGetters } from 'vuex'
import {
  DetailLinkActionCellTemplate, DurationByMsCellTemplate, LinkCellTemplate,
} from '@/components/grid'
import {
  GridDefaultMixin,
} from '@/mixins/grid'

import IncidentService from '@/service/incident.service'

export default {
  components: {
    Grid,
    BButton,
    BButtonGroup,
    BOverlay,
    GridToolbar,
    DetailLinkActionCellTemplate,
    DurationByMsCellTemplate,
    LinkCellTemplate,
  },
  mixins: [
    GridDefaultMixin,
  ],
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataItems: [],
      isLoading: false,
      selectedFilter: 'open',
      enableAssetFilter: true,
      columns: [
        {
          cell: 'cellActionTemplate',
          field: 'cellActionTemplate',
          title: ' ',
          locked: true,
          filterable: false,
          sortable: false,
          groupable: false,
          width: '40px',
          orderIndex: 0,
          static: true,
          columnMenu: false,
        },
        {
          field: 'number',
          title: 'Ticket number',
          orderIndex: 1,
          width: '100px',
        },
        {
          field: 'title',
          title: 'Title',
          orderIndex: 2,
          width: '500px',
        },
        {
          field: 'statusDisplayName',
          title: 'Status',
          orderIndex: 3,
          width: '150px',
        },
        {
          field: 'pendingTime',
          title: 'Wait until',
          orderIndex: 4,
          width: '150px',
          filter: 'date',
          type: 'date',
          format: '{0:g}',
        },
        {
          field: 'updatedOn',
          title: 'Updated on',
          orderIndex: 5,
          width: '200px',
          filter: 'date',
          type: 'date',
          format: '{0:g}',
        },
        {
          field: 'ownerUser.email',
          title: 'Owner',
          orderIndex: 6,
          width: '200px',
        },
        {
          field: 'customerUser.email',
          title: 'Customer',
          orderIndex: 7,
          width: '200px',
        },
        {
          cell: 'assetHostnameCellTemplate',
          field: 'asset.hostname',
          title: 'Asset',
          orderIndex: 8,
          width: '250px',
        },
        {
          field: 'severity',
          title: 'Severity',
          orderIndex: 9,
          width: '80px',
        },
        {
          cell: 'cellDurationTemplate',
          field: 'durationMilliseconds',
          title: 'Duration',
          orderIndex: 10,
          width: '150px',
          filter: 'numeric',
          type: 'numeric',
          filterable: false,
          columnMenu: false,
        },
        {
          field: 'customerRefId',
          title: 'Customer Ref. Id',
          orderIndex: 11,
          width: '200px',
        },
        {
          field: 'asset.accountName',
          title: 'Account',
          orderIndex: 12,
          width: '200px',
        },
        {
          field: 'asset.ouDisplayName',
          title: 'Organization Unit',
          orderIndex: 13,
          width: '200px',
        },
        {
          cell: 'assetSystemNumberCellTemplate',
          field: 'asset.systemNumber',
          title: 'Asset Number',
          orderIndex: 14,
          width: '150px',
        },
        {
          field: 'partnerId',
          title: 'Partner Id',
          orderIndex: 15,
          width: '150px',
        },
        {
          field: 'vendorId',
          title: 'Vendor Id',
          orderIndex: 16,
          width: '150px',
        },
        {
          field: 'createdByTechnicianUser.email',
          title: 'Created by technician',
          orderIndex: 17,
          width: '200px',
        },
        {
          field: 'createdByUser.email',
          title: 'Created by',
          orderIndex: 18,
          width: '200px',
        },
        {
          field: 'createdOn',
          title: 'Created on',
          orderIndex: 19,
          width: '200px',
          filter: 'date',
          type: 'date',
          format: '{0:d}',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    result() {
      const self = this
      let result = this.dataItems.concat()

      /* Normalize data  */
      result.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.asset = item.asset || {} // make sure its not null to avoid exceptions
        // eslint-disable-next-line no-param-reassign
        item.statusDisplayName = self.$options.filters.incidentStatusDisplayName(item.status)
        // eslint-disable-next-line no-param-reassign
        item.durationMilliseconds = self.$moment.duration(item.duration).asMilliseconds()
      })

      if (this.enableAssetFilter === true) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        result = result.filter(x => x.asset.id === this.asset.id)
      }

      return process(result, this.dataState)
    },
  },
  watch: {
    selectedFilter() {
      this.loadData()
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true

      const query = {
        maxResultCount: 1000,
        sorting: 'CreatedOn desc',
        includeDefaultOu: true,
      }

      if (this.selectedFilter === 'open') {
        query.status = 0
      }

      const queryOpts = {}
      if (this.asset.tenantId) {
        queryOpts.impersonateAsTenant = this.asset.tenantId
      } else {
        queryOpts.disableTenantFilter = true
      }

      IncidentService.getAllListAsync(query, queryOpts)
        .then(result => {
          this.dataItems = result
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
