<template>
  <div style="display: flex; flex-direction: row; gap: 10px;">
    <div>
      <feather-icon
        :class="{ 'danger': status === 30, 'warning': status === 20, 'info': status === 10,'success': status === 0}"
        icon="CircleIcon"
      />
    </div>
    <div
      v-if="text"
      v-html="text"
    />
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Number,
      default: () => null,
    },
    text: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>
  .info {
    fill: var(--info);
    color: var(--info);
  }
  .success {
    fill: var(--success);
    color: var(--success);
  }
  .warning {
    fill: var(--warning);
    color: var(--warning);
  }
  .danger {
    fill: var(--danger);
    color: var(--danger);
  }
</style>
