<template>
  <td
    v-if="renderCell"
    :class="cellBackgroundClass"
  >
    {{ valueAsString }}
  </td>
</template>

<script>

import UiAttributeCellMixin from './UiAttributeCellMixin'

export default {
  mixins: [
    UiAttributeCellMixin,
  ],
}
</script>
