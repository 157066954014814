<template>
  <td
    v-if="renderCell"
    class="pt-0 pb-0"
  >
    {{ durationText }}
  </td>
</template>

<script>

export default {
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
      default: null,
    },
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
    durationText() {
      if (!this.duration) {
        return '-'
      }

      return this.$moment.duration(this.duration).humanize()
    },
  },
}
</script>
