// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig } from './serviceManager'

export default {
  async getAllListAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    if (!requestConfig.params.maxResultCount) {
      requestConfig.params.maxResultCount = 50
    }

    let page = 0
    let totalCount = 0

    const result = []

    do {
      requestConfig.params.skipCount = page * requestConfig.params.maxResultCount

      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get('/core/incident', requestConfig)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getAsync(id, config) {
    const result = await axios.get(`/core/incident/${id}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async getCreateInfoAsync(assetId, config) {
    const result = await axios.get(`/core/incident/create-info/?assetId=${assetId}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async getAttachmentAsync(ticketId, articleId, id, config) {
    const result = await axios.get(
      `/core/incident/${ticketId}/article/${articleId}/attachment/${id}`,
      buildAxiosRequestConfig(
        config,
        requestConfig => {
          // eslint-disable-next-line no-param-reassign
          requestConfig.responseType = 'arraybuffer'
        },
      ),
    )

    return result.data
  },

  async createAsync(data, config) {
    const result = await axios.post('/core/incident', data, buildAxiosRequestConfig(config))
    return result.data
  },

  async createArticleAsync(id, data, config) {
    const result = await axios.post(`/core/incident/${id}/article`, data, buildAxiosRequestConfig(config))
    return result.data
  },

  async updateAsync(id, data, config) {
    const result = await axios.put(`/core/incident/${id}`, data, buildAxiosRequestConfig(config))
    return result.data
  },

  async getAttachmentUploadLinkAsync(attachmentFileName, config) {
    const result = await axios.get(`/core/incident/attachment/uploadUrl?attachmentFileName=${attachmentFileName}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async getAttachmentDownloadLinkAsync(ticketId, attachmentId, config) {
    const result = await axios.get(`/core/incident/${ticketId}/attachment/downloadUrl?attachmentId=${attachmentId}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async closeAsync(id, config) {
    const result = await axios.put(`/core/incident/${id}/close`, {}, buildAxiosRequestConfig(config))
    return result.data
  },

  async reopenAsync(id, config) {
    const result = await axios.put(`/core/incident/${id}/reopen`, {}, buildAxiosRequestConfig(config))
    return result.data
  },

  async triggerSynchronizeAsync(id, config) {
    const result = await axios.post(`/core/incident/${id}/synchronize`, {}, buildAxiosRequestConfig(config))
    return result.data
  },

  async triggerSynchronizeAllAsync(config) {
    const result = await axios.post('/core/incident/synchronize', {}, buildAxiosRequestConfig(config))
    return result.data
  },
}
