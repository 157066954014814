<template>
  <td
    v-if="renderCell"
    class="pt-0 pb-0"
  >
    <b-avatar
      size="40"
      variant="light-primary"
      class="profile-image"
      :src="dataItem.profileImageUrl"
    />
  </td>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    dataItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
  },
}
</script>

<style scoped>
    .profile-image {
        margin-top: 5px;
        margin-bottom: 5px;
    }
</style>
