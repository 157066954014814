import { mapGetters } from 'vuex'
import { provideIntlService } from '@progress/kendo-vue-intl'
import { getNestedValue, parsers } from '@progress/kendo-vue-grid/dist/es/utils'

export default {
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    dataItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      intl: null,
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
    cellBackgroundClass() {
      if (this.cellStatus >= 30) {
        return 'bg-danger'
      }
      if (this.cellStatus === 20) {
        return 'bg-warning'
      }
      if (this.cellStatus === 10) {
        return 'bg-info'
      }

      return null
    },
    uiAttributes() {
      if (this.isHost === true) {
        return this.dataItem.hostMonitorUiAttributes
      }

      return this.dataItem.tenantMonitorUiAttributes
    },
    cellStatus() {
      if (this.uiAttributes) {
        const result = this.uiAttributes.find(x => x.uiAttribute === this.field)
        if (result) {
          return result.status
        }
      }

      return null
    },
    valueAsString() {
      const data = getNestedValue(this.$props.field, this.$props.dataItem)
      let dataAsString = ''

      if (data !== undefined && data !== null) {
        // eslint-disable-next-line no-nested-ternary
        dataAsString = this.$props.format ? this.$props.type ? this.intl.format(this.$props.format, parsers[this.$props.type](data, this.intl)) : this.intl.format(this.$props.format, data) : data.toString()
      }

      return dataAsString
    },
  },
  created: function created() {
    this.intl = provideIntlService(this)
  },
}
