<template>
  <b-button
    size="sm"
    @click="$emit('click')"
  >
    {{ label }}
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
}

</script>
