<template>
  <td
    v-if="renderCell"
    class="pt-0 pb-0"
  >
    <div
      :id="`${id}_popover_text`"
    >
      {{ previewText }}
      <b-popover
        :target="`${id}_popover_text`"
        triggers="hover"
      >
        <template
          v-if="popoverTitle"
          #title
        >
          Internal note
        </template>

        {{ text }}
      </b-popover>
    </div>
  </td>
</template>

<script>

import { BPopover } from 'bootstrap-vue'

export default {
  components: {
    BPopover,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    popoverTitle: {
      type: String,
      default: () => null,
    },
    previewLength: {
      type: Number,
      default: 80,
    },
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
    previewText() {
      if (this.text) {
        return this.text.length > this.previewLength ? `${this.text.substr(0, this.previewLength - 1)}...` : this.text
      }

      return ''
    },
  },
}
</script>
