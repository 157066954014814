<template>
  <div>
    <b-button
      size="sm"
      :variant="gridViewIsOriginal ? 'secondary' : 'outline-info'"
      @click="isShow=true"
    >
      Columns
    </b-button>

    <b-modal
      v-model="isShow"
      title="Manage columns"
      ok-only
      ok-title="Close"
    >
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Visisble</th>
            <th>Locked</th>
            <th>Column</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(column, idx) in orderedColumns"
            :key="column.field"
          >
            <td>
              <b-form-checkbox
                :checked="!column.hidden"
                :disabled="(!column.hidden && oneVisibleColumn) || column.static"
                @change="onShowHideClick(idx)"
              />
            </td>
            <td>
              <b-form-checkbox
                :checked="column.locked"
                @change="onLockedClick(idx)"
              />
            </td>
            <td>{{ column.title ? column.title : '-' }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>

  </div>
</template>

<script>

import {
  BFormCheckbox,
  BButton,
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
    BButton,
    BModal,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    originalColumns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isShow: false,
    }
  },
  computed: {
    orderedColumns() {
      const result = this.columns
      return result.sort((a, b) => a.orderIndex - b.orderIndex)
    },
    oneVisibleColumn() {
      return this.columns.filter(c => !c.hidden).length === 1
    },
    gridViewIsOriginal() {
      for (let i = 0; i < this.originalColumns.length; i += 1) {
        const originalColumn = this.originalColumns[i]
        const originalColumnLocked = typeof originalColumn.locked === 'undefined' ? false : originalColumn.locked
        const originalColumnHidden = typeof originalColumn.hidden === 'undefined' ? false : originalColumn.hidden

        for (let j = 0; j < this.columns.length; j += 1) {
          const column = this.columns[j]
          const columnLocked = typeof column.locked === 'undefined' ? false : column.locked
          const columnHidden = typeof column.hidden === 'undefined' ? false : column.hidden

          if (originalColumn.field === column.field) {
            if (originalColumnLocked !== columnLocked
                || originalColumnHidden !== columnHidden) {
              return false
            }
          }
        }
      }

      return true
    },
  },
  methods: {
    onShowHideClick(id) {
      const changedColumns = this.columns.map((column, idx) => (idx === id ? { ...column, hidden: !column.hidden } : column))
      this.$emit('columnssubmit', changedColumns)
    },
    onLockedClick(id) {
      const changedColumns = this.columns.map((column, idx) => (idx === id ? { ...column, locked: !column.locked } : column))
      this.$emit('columnssubmit', changedColumns)
    },
  },
}

</script>
