<template>
  <td
    v-if="renderCell"
    class="pt-0 pb-0"
    :class="cellBackgroundClass"
  >
    {{ value | prettyBytes(prettyByteOptions) }}
  </td>
</template>

<script>
import UiAttributeCellMixin from './UiAttributeCellMixin'

export default {
  mixins: [
    UiAttributeCellMixin,
  ],
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: null,
    },
    prettyByteOptions: {
      type: Object,
      default: null,
    },
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
  },
}
</script>
