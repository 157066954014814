// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig } from './serviceManager'

export default {

  async getAsync(id, config) {
    const response = await axios.get(`/core/gridviews/user/${id}`, buildAxiosRequestConfig(config))
    return response
  },

  async getListByGridAsync(gridId, config) {
    const response = await axios.get(`/core/gridviews/user?gridId=${gridId}`, buildAxiosRequestConfig(config))
    return response
  },

  async createAsync(data, config) {
    const response = await axios.post('/core/gridviews/user', data, buildAxiosRequestConfig(config))
    return response
  },

  async deleteAsync(id, config) {
    const response = await axios.delete(`/core/gridviews/user/${id}`, buildAxiosRequestConfig(config))
    return response
  },

  async getDefaultAsync(gridId, config) {
    const response = await axios.get(`/core/gridviews/user/default?gridId=${gridId}`, buildAxiosRequestConfig(config))
    return response
  },

  async setDefaultAsync(id, config) {
    const response = await axios.post(`/core/gridviews/user/${id}/default`, buildAxiosRequestConfig(config))
    return response
  },

  async unsetDefaultAsync(id, config) {
    const response = await axios.delete(`/core/gridviews/user/${id}/default`, buildAxiosRequestConfig(config))
    return response
  },

}
