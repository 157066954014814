<template>
  <b-spinner
    v-if="isLoading"
    small
    style="margin-left: auto"
    label="Loading..."
  />
</template>

<script>

import {
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
