<template>
  <b-button
    size="sm"
    @click="exportExcel"
  >
    Export to Excel
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { filterBy } from '@progress/kendo-data-query'
import { saveExcel } from '@progress/kendo-vue-excel-export'

export default {
  components: {
    BButton,
  },
  props: {
    dataItems: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    dataState: {
      type: Object,
      required: true,
    },
    exportFileName: {
      type: String,
      default: 'export',
    },
  },
  methods: {
    exportExcel() {
      const data = filterBy(this.dataItems, this.dataState.filter) // filter export data to match with current grid filter
      const exportColumns = this.columns.filter(
        col => typeof col.hidden === 'undefined' || col.hidden === false,
      ) // use all active columns which are not hidden

      saveExcel({
        data,
        fileName: this.exportFileName,
        columns: exportColumns,
      })
    },
  },
}

</script>
