<template>
  <td
    v-if="renderCell"
    class="pt-0 pb-0"
  >
    <b-link
      :to="{ name: to, params: toParams }"
      @click="$emit('click')"
    >
      <feather-icon
        class="cursor-pointer"
        icon="EyeIcon"
        size="12"
      />
    </b-link>
  </td>
</template>

<script>

import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: '',
    },
    toParams: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
  },
}
</script>
