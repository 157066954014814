<template>
  <td
    v-if="renderCell"
    class="pt-0 pb-0"
  >
    <ul class="list">
      <li
        v-for="item in listItems"
        :key="item"
      >
        {{ item }}
      </li>
    </ul>
  </td>
</template>

<script>

export default {
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    listItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
  },
}
</script>

<style scoped>
    ul.list {
        margin: 10px 0 10px 0;
        padding-left: 10px;
    }
</style>
